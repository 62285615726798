import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги з догляду за автомобілем в автосервісі TERRAxSPIKE
			</title>
			<meta name={"description"} content={"Піднесіть свою поїздку - відкрийте для себе наші послуги з догляду за автомобілем преміум-класу в автосервісі TERRAxSPIKE"} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги з догляду за автомобілем в автосервісі TERRAxSPIKE"} />
			<meta property={"og:description"} content={"Піднесіть свою поїздку - відкрийте для себе наші послуги з догляду за автомобілем преміум-класу в автосервісі TERRAxSPIKE"} />
			<meta property={"og:image"} content={"https://terraxspike.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://terraxspike.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://terraxspike.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://terraxspike.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://terraxspike.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://terraxspike.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://terraxspike.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наші послуги
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					В автосервісі TERRAxSPIKE ми пропонуємо широкий спектр послуг з догляду за автомобілем, щоб ваш автомобіль виглядав бездоганно та працював якнайкраще. Від ретельної деталізації до спеціалізованих процедур, ми прагнемо надати першокласний сервіс, який перевершить ваші очікування.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://terraxspike.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Зовнішня обробка
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Ручне миття: Делікатне ручне миття забезпечить ретельне очищення автомобіля без ризику подряпин і вихрових слідів.
							<br />
							<br />
							Захист лакофарбового покриття: Захистіть лакофарбове покриття вашого автомобіля від впливу навколишнього середовища за допомогою наших преміальних засобів для захисту лакофарбового покриття, щоб воно виглядало блискуче і було готове до показу в автосалоні.
							<br />
							<br />
							Керамічне покриття: Покращуйте блиск і довговічність зовнішнього вигляду вашого автомобіля за допомогою наших керамічних покриттів, що забезпечують тривалий захист від бруду, жиру та ультрафіолетових променів.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://terraxspike.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="20px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Оздоблення салону
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Прибирання салону: Попрощайтеся з пилом і сміттям за допомогою нашої ретельної послуги з прибирання салону, яка залишить салон вашого автомобіля бездоганно чистим і свіжим.
							<br />
							<br />
							Кондиціонування шкіри: Відновіть і захистіть шкіряну оббивку вашого автомобіля за допомогою наших спеціалізованих засобів для кондиціонування шкіри, зберігаючи її м'якою, еластичною та без тріщин.
							<br />
							<br />
							Усунення неприємних запахів: Видаліть небажані запахи з салону вашого автомобіля за допомогою наших ефективних процедур з усунення неприємних запахів, залишивши його пахнути чистим і привабливим.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://terraxspike.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="20px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Спеціалізовані процедури
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Реставрація фар: Покращуйте видимість та зовнішній вигляд ваших фар за допомогою наших професійних послуг з реставрації фар, забезпечуючи безпеку та стиль на дорозі.
							<br />
							<br />
							Догляд за колесами та шинами: Оживіть свої колеса та шини за допомогою наших послуг з чищення коліс та шиномонтажу, надаючи їм гладкого та відполірованого вигляду, що доповнює загальний естетичний вигляд вашого автомобіля.
							<br />
							<br />
							Очищення моторного відсіку: Підтримуйте ваш моторний відсік у чистоті та доглянутому стані за допомогою наших послуг з очищення моторного відсіку, покращуючи як зовнішній вигляд, так і довговічність компонентів двигуна вашого транспортного засобу.

						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Зв'яжіться з нами
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Щоб отримати додаткову інформацію про наші послуги або записатися на прийом, будь ласка, зв'яжіться з нами за телефоном 093 634 68 86 або напишіть нам на contact@terraxspike.com. Наша дружня команда завжди готова допомогти вам і надати кваліфіковану консультацію щодо підтримки краси та продуктивності вашого автомобіля.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://terraxspike.com/img/8.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});